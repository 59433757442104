var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.editMode && !_vm.isYoukuVideo)?_c('div',[(!_vm.validUrl)?_c('div',[_c('h2',[_vm._v(_vm._s(_vm._f("translate")('lp.invalidContent')))])]):_c('div',[(_vm.loadingThumbnail)?_c('loader'):_vm._e(),(!_vm.loadingThumbnail)?_c('img',{staticClass:"w-100",attrs:{"src":_vm.videoThumbnail}}):_vm._e()],1)]):_c('div',[(_vm.validUrl && _vm.isYoutube && !_vm.consentForYoutube && !_vm.editMode)?_c('ask-consent-overlay',{attrs:{"type":"youtube"}}):_vm._e(),(_vm.validUrl && _vm.isVimeo && !_vm.consentForVimeo && !_vm.editMode)?_c('ask-consent-overlay',{attrs:{"type":"vimeo"}}):_vm._e(),(_vm.validUrl && _vm.isTwentythree && !_vm.consentForTwentyThree && !_vm.editMode)?_c('ask-consent-overlay',{attrs:{"type":"twentyThree"}}):_vm._e(),(_vm.validUrl && _vm.isVideoTool && !_vm.consentForVideoTool && !_vm.editMode)?_c('ask-consent-overlay',{attrs:{"type":"videoTool"}}):_vm._e(),(_vm.validUrl && _vm.isDreamBroker && !_vm.consentForDreamBroker && !_vm.editMode)?_c('ask-consent-overlay',{attrs:{"type":"dreamBroker"}}):_vm._e(),(!_vm.validUrl)?_c('div',[_c('h2',[_vm._v(_vm._s(_vm._f("translate")('lp.invalidContent')))])]):_vm._e(),(
      _vm.editMode ||
        (((_vm.consentForVimeo && _vm.isVimeo) ||
          (_vm.consentForYoutube && _vm.isYoutube) ||
          (_vm.consentForTwentyThree && _vm.isTwentythree) ||
          (_vm.consentForVideoTool && _vm.isVideoTool) ||
          (_vm.consentForDreamBroker && _vm.isDreamBroker) ||
          (!_vm.isYoutube && !_vm.isVimeo && !_vm.isTwentythree && !_vm.isVideoTool && !_vm.isDreamBroker)) &&
          _vm.validUrl)
    )?_c('div',{staticClass:"video embed-responsive embed-responsive-16by9"},[_c('iframe',{attrs:{"src":_vm.getEmbedUrl(_vm.url),"frameborder":"0","allow":"accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture","allowfullscreen":""}})]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }