<template>
  <div v-if="editMode && !isYoukuVideo">
    <div v-if="!validUrl">
      <h2>{{ 'lp.invalidContent' | translate }}</h2>
    </div>
    <div v-else>
      <loader v-if="loadingThumbnail"></loader>
      <img :src="videoThumbnail" class="w-100" v-if="!loadingThumbnail" />
    </div>
  </div>
  <div v-else>
    <ask-consent-overlay
      v-if="validUrl && isYoutube && !consentForYoutube && !editMode"
      type="youtube"
    ></ask-consent-overlay>
    <ask-consent-overlay v-if="validUrl && isVimeo && !consentForVimeo && !editMode" type="vimeo"></ask-consent-overlay>
    <ask-consent-overlay
      v-if="validUrl && isTwentythree && !consentForTwentyThree && !editMode"
      type="twentyThree"
    ></ask-consent-overlay>
    <ask-consent-overlay
      v-if="validUrl && isVideoTool && !consentForVideoTool && !editMode"
      type="videoTool"
    ></ask-consent-overlay>
    <ask-consent-overlay
      v-if="validUrl && isDreamBroker && !consentForDreamBroker && !editMode"
      type="dreamBroker"
    ></ask-consent-overlay>
    <div v-if="!validUrl">
      <h2>{{ 'lp.invalidContent' | translate }}</h2>
    </div>
    <div
      class="video embed-responsive embed-responsive-16by9"
      v-if="
        editMode ||
          (((consentForVimeo && isVimeo) ||
            (consentForYoutube && isYoutube) ||
            (consentForTwentyThree && isTwentythree) ||
            (consentForVideoTool && isVideoTool) ||
            (consentForDreamBroker && isDreamBroker) ||
            (!isYoutube && !isVimeo && !isTwentythree && !isVideoTool && !isDreamBroker)) &&
            validUrl)
      "
    >
      <iframe
        :src="getEmbedUrl(url)"
        frameborder="0"
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        allowfullscreen
      ></iframe>
    </div>
  </div>
</template>

<script>
import BaseContentComponent from './BaseContentComponent.vue';
import { mapGetters } from 'vuex';
import AskConsentOverlay from '@/app/components/ui/consentOverlay/AskConsentOverlay.vue';
export default {
  components: { AskConsentOverlay },
  extends: BaseContentComponent,
  props: {
    url: {
      type: String
    },
    editMode: {
      type: Boolean
    }
  },
  data() {
    return {
      videoThumbnail: '',
      loadingThumbnail: false,
      previousUrl: null,
      validUrl: true
    };
  },
  computed: {
    ...mapGetters('settings', [
      'consentForYoutube',
      'consentForVimeo',
      'consentForTwentyThree',
      'consentForVideoTool',
      'consentForDreamBroker'
    ]),
    isVimeo() {
      return this.url.includes('vimeo');
    },
    isYoutube() {
      return this.url.includes('youtu');
    },
    isTwentythree() {
      return this.url.includes('twentythree');
    },
    isVideoTool() {
      return this.url.includes('videotool');
    },
    isDreamBroker() {
      return this.url.includes('dreambroker');
    }
  },
  mounted() {
    this.getThumbnailUrl(this.url);
    this.previousUrl = this.url;
  },
  watch: {
    url(value) {
      this.getThumbnailUrl(value);
    }
  },
  methods: {
    getThumbnailUrl(url) {
      if (url === this.previousUrl) {
        return;
      }

      this.previousUrl = url;
      this.loadingThumbnail = true;
      this.validUrl = true;
      if (this.isYoutube) {
        const id = this.getYouTubeId(url);
        this.videoThumbnail = `https://img.youtube.com/vi/${id}/0.jpg`;
        this.loadingThumbnail = false;
      } else if (this.isVimeo) {
        let { companyId, templateId } = { ...JSON.parse(localStorage.getItem('admin_user')) };
        if (!companyId || !templateId) {
          return;
        }
        const id = this.getVimeoId(url);
        let thumbUrl = `/v1/companies/${companyId}/templates/${templateId}/learningpages/items/oembed/${id}`;
        this.$http.get(thumbUrl, { cors: false }).then((res) => {
          this.videoThumbnail = res.data.thumbnail_url_with_play_button;
          this.loadingThumbnail = false;
        });
      } else if (url.includes('youku')) {
        this.videoThumbnail = this.validUrl = true;
        this.loadingThumbnail = false;
      } else if (url.includes('v.qq.com')) {
        this.videoThumbnail = this.validUrl = true;
        this.loadingThumbnail = false;
      } else if (url.includes('dreambroker')) {
        this.videoThumbnail = this.validUrl = true;
        this.loadingThumbnail = false;
      } else if (url.includes('twentythree')) {
        this.videoThumbnail = this.validUrl = true;
        this.loadingThumbnail = false;
      } else if (url.includes('videotool')) {
        this.videoThumbnail = this.validUrl = true;
        this.loadingThumbnail = false;
      } else {
        this.validUrl = false;
      }
    },
    getEmbedUrl(url) {
      if (!url) {
        return;
      }
      this.validUrl = true;
      if (url.includes('youtu')) {
        const id = this.getYouTubeId(url);
        return `https://www.youtube-nocookie.com/embed/${id}`;
      } else if (url.includes('vimeo')) {
        const id = this.getVimeoId(url);
        return `https://player.vimeo.com/video/${id}?dnt=1`;
      } else if (url.includes('youku')) {
        const id = this.getYoukuId(url);
        if (id) {
          return `https://player.youku.com/embed/${id}`;
        } else {
          this.validUrl = false;
        }
      } else if (url.includes('v.qq.com')) {
        const id = this.getTencentId(url);
        if (id) {
          return `https://v.qq.com/txp/iframe/player.html?vid=${id}`;
        } else {
          this.validUrl = false;
        }
      } else if (url.includes('dreambroker')) {
        this.validUrl = true;
        return url;
      } else if (url.includes('twentythree')) {
        this.validUrl = true;
        const fid = this.getTwentyThreeId(url);
        return `//video.twentythree.com/v.ihtml/player.html?source=share&photo%5fid=${fid}`;
      } else if (url.includes('videotool')) {
        this.validUrl = true;
        return url;
      } else {
        this.validUrl = false;
      }
    },
    getYoukuId(url) {
      if (url.includes('player.youku.com/embed/') && url.includes('frameborder')) {
        let key = url.slice(url.indexOf('player.youku.com/embed/') + 'player.youku.com/embed/'.length);
        key = key.slice(0, key.indexOf('frameborder') - 2);
        return key;
      }

      if (url.includes('player.youku.com/embed/')) {
        let key = url.slice(url.indexOf('player.youku.com/embed/') + 'player.youku.com/embed/'.length);
        return key;
      }

      if (url.includes('v.youku.com')) {
        let key = url.slice(url.indexOf('id_') + 3, url.indexOf('.html'));
        return key;
      }
    },
    getYouTubeId(url) {
      if (url.includes('v=')) return url.match(/v=([A-Za-z0-9_-]+)/)[1];
      if (url.includes('embed')) return url.match(/embed\/([A-Za-z0-9_-]+)/)[1];
      if (url.includes('youtu.be')) return url.match(/youtu.be\/([A-Za-z0-9_-]+)/)[1];
    },
    getVimeoId(url) {
      return url.match(/vimeo.com\/(\d*)\D*/)[1];
    },
    getTwentyThreeId(url) {
      return url.match(/fid=[0-9]*/)[0].replace('fid=', '');
    },
    getTencentId(url) {
      let key = url.slice(url.lastIndexOf('/') + 1, url.indexOf('.html'));
      return key;
    },
    isYoukuVideo(url) {
      return url.includes('youku');
    }
  }
};
</script>
